import React from "react"
import { graphql } from "gatsby"
import { Box, Themed } from "theme-ui"
import LayoutGrid from "../components/layout-grid"
import Aside from "../components/aside"
import MenuAbout from "../components/menu-about"
import MenuSocial from "../components/menu-social"
import { useMediaQuery } from 'react-responsive'
import { lighten } from "@theme-ui/color"
import Seo from "../components/seo"

const Pages = ({ data }) => {

  const projectData = data.markdownRemark
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 })
  const isTabletOrMobileDevice = useMediaQuery({ maxDeviceWidth: 1224 })

  return (
    <LayoutGrid>
    <Seo
      title={projectData.frontmatter.title}
      description={projectData.excerpt}
      image={projectData.frontmatter.image.childImageSharp.original.src}
    />
      {isDesktopOrLaptop &&
        <Aside>
          {projectData.frontmatter.template === `about` &&
            <MenuAbout />
          }
          <MenuSocial />
        </Aside>
      }
      <Box
        as={`main`}
        className="content"
        sx={{
          maxWidth: [`100vw`, `100vw`, `70vw`, `50vw`, `50vw`, `45vw`],
          h1: {
            "&:after": {
              backgroundColor: lighten(`primary`, 0.4),
              content: `""`,
              display: `block`,
              height: `1px`,
              my: 3,
              width: 6,
            }
          },
          "p:first-of-type": {
            marginTop: 0,
          }
        }}
      >
        <Themed.h1>{projectData.frontmatter.title}</Themed.h1>
        <Box
          sx={{
            variant: 'styles',
          }}
          dangerouslySetInnerHTML={{ __html: projectData.html }}
        />
        {isTabletOrMobileDevice &&
          <Box
            sx={{
              mt: 4,
            }}
          >
            {projectData.frontmatter.template === `about` &&
              <MenuAbout />
            }
            <MenuSocial />
          </Box>
        }
      </Box>
    </LayoutGrid>
  )

}

export default Pages

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt(format: PLAIN)
      frontmatter {
        template
        title
        image {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
    }
  }
`
